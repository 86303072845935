export const OctanLogo = () => {
  return (
    <svg width="160" height="42" viewBox="0 0 160 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1119_38212)">
        <path
          d="M41.9844 28.2038V11.7853H58.5808V28.2038H41.9844ZM55.2652 24.9227V15.0654H45.301V24.9227H55.2652Z"
          fill="white"
        />
        <path d="M70.6172 28.2038H87.2136V24.9227H73.9328V15.0654H87.2136V11.7853H70.6172V28.2038Z" fill="white" />
        <path d="M94.8672 15.0654H101.515V28.2038H104.831V15.0654H111.464V11.7853H94.8672V15.0654Z" fill="white" />
        <path
          d="M135.727 28.2038L129.095 11.7853H125.763L119.131 28.2038H122.925L127.437 16.7059L130.641 24.9227H127.437V28.2038H135.727Z"
          fill="white"
        />
        <path
          d="M146.711 28.2038V16.9265L156.675 28.2038H159.991V11.7853H156.675V23.4401L146.711 11.7853H143.395V28.2038H146.711Z"
          fill="white"
        />
        <path
          d="M15.4411 37.3193C14.9469 37.3193 14.5009 37.461 14.1183 37.6978L4.75973 32.3511C4.75973 32.3036 4.75973 32.257 4.75973 32.2095C4.75973 31.2946 4.21753 30.49 3.43697 30.1115V11.9584C4.21855 11.5637 4.75973 10.7753 4.75973 9.84522C4.75973 9.79765 4.75973 9.75009 4.75973 9.70353L9.73364 6.86474L21.7224 13.7092V27.1472L17.8482 24.9237C17.8482 24.8762 17.8482 24.8286 17.8482 24.782C17.8482 23.4886 16.7955 22.4321 15.4728 22.4321C14.1654 22.4321 13.0973 23.4886 13.0973 24.782C13.0973 26.0754 14.1654 27.132 15.4728 27.132C15.9669 27.132 16.4129 26.9903 16.7955 26.7373L22.2001 29.8281C22.3597 29.9384 22.5664 30.0174 22.7904 30.0174C23.3807 30.0174 23.8584 29.5437 23.8584 28.9608V13.0939V12.4948L23.3163 12.1952L10.2748 4.73539L9.74899 4.43582L9.20679 4.73539L3.69068 7.88994C3.32444 7.63794 2.87738 7.49524 2.38326 7.49524C1.07584 7.49524 0.0078125 8.55182 0.0078125 9.84522C0.0078125 10.7753 0.550011 11.5647 1.33057 11.9584V30.1115C0.548988 30.49 0.0078125 31.2946 0.0078125 32.2095C0.0078125 33.518 1.07584 34.5594 2.38326 34.5594C2.86203 34.5594 3.32341 34.4177 3.69068 34.1647L13.0646 39.5276C13.0646 39.5751 13.0646 39.6227 13.0646 39.6693C13.0646 40.9627 14.1173 42.0192 15.44 42.0192C16.7475 42.0192 17.8155 40.9627 17.8155 39.6693C17.8165 38.3607 16.7485 37.3193 15.4411 37.3193Z"
          fill="url(#paint0_linear_1119_38212)"
        />
        <path
          d="M29.5514 30.1115V11.9584C30.333 11.5637 30.8742 10.7753 30.8742 9.84521C30.8742 8.55182 29.8061 7.49524 28.4824 7.49524C28.0036 7.49524 27.5575 7.63692 27.1749 7.88994L17.8164 2.54329C17.8164 2.49572 17.8164 2.44815 17.8164 2.4016C17.8164 1.09302 16.7483 0.0354309 15.4409 0.0354309C14.1182 0.0354309 13.0655 1.09201 13.0655 2.4016C13.0655 3.695 14.1182 4.75157 15.4409 4.75157C15.9197 4.75157 16.3657 4.60989 16.7483 4.35688L26.1222 9.70353C26.1059 9.75109 26.1059 9.79866 26.1059 9.84521C26.1059 10.7753 26.6481 11.5647 27.4286 11.9584V30.1115C26.6471 30.49 26.1059 31.2946 26.1059 32.2095C26.1059 32.257 26.1059 32.3036 26.1222 32.3511L21.132 35.2061L9.14322 28.3616V14.9237L13.1606 17.2109C13.1442 17.2585 13.1442 17.306 13.1442 17.3526C13.1442 18.6612 14.2123 19.7026 15.5197 19.7026C16.8425 19.7026 17.9115 18.6612 17.9115 17.3526C17.9115 16.0592 16.8435 15.0026 15.5197 15.0026C15.0409 15.0026 14.5949 15.1443 14.2123 15.3973L8.61637 12.1952C8.45678 12.1163 8.26547 12.0535 8.07417 12.0535C7.50026 12.0535 7.02148 12.5271 7.02148 13.0949V28.9608V29.5599L7.54732 29.8595L20.6041 37.3193L21.1299 37.6189L21.6558 37.3193L27.1719 34.1647C27.5545 34.4167 28.0005 34.5594 28.4793 34.5594C29.802 34.5594 30.8711 33.518 30.8711 32.2095C30.8742 31.2946 30.332 30.49 29.5514 30.1115Z"
          fill="url(#paint1_linear_1119_38212)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1119_38212"
          x1="0.00851838"
          y1="23.2274"
          x2="23.8587"
          y2="23.2274"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00FF7C" />
          <stop offset="1" stopColor="#00C4FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1119_38212"
          x1="7.02416"
          y1="18.8271"
          x2="30.8743"
          y2="18.8271"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00FF7C" />
          <stop offset="1" stopColor="#00C4FF" />
        </linearGradient>
        <clipPath id="clip0_1119_38212">
          <rect width="160" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OctanLogoBlack = () => {
  return (
    <svg width="140" height="38" viewBox="0 0 140 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3337_18261)">
        <path
          d="M36.7368 25.3462V10.8823H51.2587V25.3462H36.7368ZM48.3575 22.4557V13.7719H39.6389V22.4557H48.3575Z"
          fill="#1C1C1C"
        />
        <path d="M61.7905 25.3462H76.3124V22.4557H64.6917V13.7719H76.3124V10.8823H61.7905V25.3462Z" fill="#1C1C1C" />
        <path d="M83.0083 13.7719H88.8249V25.3462H91.727V13.7719H97.5302V10.8823H83.0083V13.7719Z" fill="#1C1C1C" />
        <path
          d="M118.762 25.3462L112.959 10.8823H110.043L104.24 25.3462H107.56L111.508 15.2171L114.311 22.4557H111.508V25.3462H118.762Z"
          fill="#1C1C1C"
        />
        <path
          d="M128.373 25.3462V15.4115L137.092 25.3462H139.993V10.8823H137.092V21.1496L128.373 10.8823H125.471V25.3462H128.373Z"
          fill="#1C1C1C"
        />
        <path
          d="M13.5104 33.3766C13.0781 33.3766 12.6878 33.5014 12.353 33.7101L4.16427 28.9999C4.16427 28.958 4.16427 28.917 4.16427 28.8751C4.16427 28.0691 3.68985 27.3603 3.00686 27.0269V11.0348C3.69075 10.6871 4.16427 9.99261 4.16427 9.17326C4.16427 9.13135 4.16427 9.08945 4.16427 9.04844L8.51645 6.54759L19.0066 12.5773V24.4155L15.6167 22.4567C15.6167 22.4148 15.6167 22.3729 15.6167 22.3319C15.6167 21.1925 14.6956 20.2617 13.5382 20.2617C12.3942 20.2617 11.4597 21.1925 11.4597 22.3319C11.4597 23.4713 12.3942 24.4021 13.5382 24.4021C13.9705 24.4021 14.3608 24.2773 14.6956 24.0544L19.4246 26.7772C19.5643 26.8744 19.7451 26.944 19.9411 26.944C20.4576 26.944 20.8757 26.5267 20.8757 26.0132V12.0352V11.5074L20.4012 11.2435L8.98998 4.67174L8.52988 4.40784L8.05545 4.67174L3.22885 7.45075C2.90839 7.22875 2.51722 7.10304 2.08486 7.10304C0.940873 7.10304 0.00634766 8.03384 0.00634766 9.17326C0.00634766 9.99261 0.480772 10.688 1.16376 11.0348V27.0269C0.479876 27.3603 0.00634766 28.0691 0.00634766 28.8751C0.00634766 30.0279 0.940873 30.9453 2.08486 30.9453C2.50379 30.9453 2.9075 30.8205 3.22885 30.5976L11.431 35.322C11.431 35.3639 11.431 35.4058 11.431 35.4468C11.431 36.5862 12.3521 37.517 13.5095 37.517C14.6535 37.517 15.5881 36.5862 15.5881 35.4468C15.589 34.294 14.6544 33.3766 13.5104 33.3766Z"
          fill="url(#paint0_linear_3337_18261)"
        />
        <path
          d="M25.8563 27.0268V11.0348C26.5401 10.6871 27.0137 9.99255 27.0137 9.1732C27.0137 8.03378 26.0792 7.10298 24.9208 7.10298C24.5019 7.10298 24.1116 7.2278 23.7768 7.45069L15.5881 2.74055C15.5881 2.69865 15.5881 2.65674 15.5881 2.61573C15.5881 1.46294 14.6536 0.53125 13.5096 0.53125C12.3522 0.53125 11.4311 1.46204 11.4311 2.61573C11.4311 3.75515 12.3522 4.68595 13.5096 4.68595C13.9285 4.68595 14.3188 4.56113 14.6536 4.33824L22.8557 9.04838C22.8414 9.09029 22.8414 9.13219 22.8414 9.1732C22.8414 9.99255 23.3159 10.688 23.9988 11.0348V27.0268C23.315 27.3603 22.8414 28.0691 22.8414 28.875C22.8414 28.9169 22.8414 28.9579 22.8557 28.9998L18.4893 31.515L7.9991 25.4853V13.6471L11.5143 15.662C11.5 15.7039 11.5 15.7458 11.5 15.7868C11.5 16.9396 12.4345 17.8571 13.5785 17.8571C14.7359 17.8571 15.6714 16.9396 15.6714 15.7868C15.6714 14.6474 14.7368 13.7166 13.5785 13.7166C13.1596 13.7166 12.7693 13.8414 12.4345 14.0643L7.5381 11.2434C7.39846 11.1739 7.23107 11.1186 7.06368 11.1186C6.5615 11.1186 6.14258 11.5359 6.14258 12.036V26.0131V26.5409L6.60268 26.8048L18.0274 33.3766L18.4875 33.6405L18.9476 33.3766L23.7742 30.5975C24.1089 30.8195 24.4992 30.9452 24.9182 30.9452C26.0756 30.9452 27.011 30.0278 27.011 28.875C27.0137 28.0691 26.5393 27.3603 25.8563 27.0268Z"
          fill="url(#paint1_linear_3337_18261)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3337_18261"
          x1="0.0069653"
          y1="20.9623"
          x2="20.8759"
          y2="20.9623"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#00FF7C" />
          <stop offset="1" stop-color="#00C4FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3337_18261"
          x1="6.14492"
          y1="17.0858"
          x2="27.0138"
          y2="17.0858"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#00FF7C" />
          <stop offset="1" stop-color="#00C4FF" />
        </linearGradient>
        <clipPath id="clip0_3337_18261">
          <rect width="140" height="37" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

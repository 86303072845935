import ReactDOM from 'react-dom/client';
import { App } from './app';
import reportWebVitals from './reportWebVitals';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.scss';

((win: Window, doc) => {
  const domContentLoaded = 'DOMContentLoaded';
  const renderRoot = () => {
    win.timeoutToReload && clearTimeout(win.timeoutToReload);

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    root.render(
      <App />
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(console.log);
  };

  const _domReadyHandler = () => {
    doc.removeEventListener(domContentLoaded, _domReadyHandler, false);
    renderRoot();
  };

  switch (doc.readyState) {
    case 'loading':
      doc.addEventListener(domContentLoaded, _domReadyHandler, false);
      break;
    case 'interactive':
    case 'complete':
    default:
      renderRoot();
  }
})(window, document);